.roadmap-control header span {
    color: #FF9859;
    /* text-shadow: 1px 0 20px rgb(200 255 0 / 31%); */
    color: white;
}

.roadmap-control {
    background-image: url(../../assets/Hell.png);
    background-size: cover;
    padding-bottom: 60px;
    padding-top: 60px;
    background-color: #FFE1C9;
    color: white;
    min-height: 1000px;
    background-repeat: repeat;
    background-size: cover;
    background-position: 50% 100%;
}

@font-face {
    font-family: newerThing;
    src: url(../../assets/fonts/Poppins-ExtraLight.ttf);
  }

@font-face {
    font-family: newThing;
    src: url(/src/components/header/Poppins-ExtraLight.ttf);
  }

.roadmap-control header {
    font-style: normal;
    font-size: 40px;
    font-weight: 700;
    color: rgb(0, 0, 0);
    margin-bottom: 20px;
    color: #88CDDB;
    font-family: newerThing;
}

.roadmap-description {
    font-size: 18px;
    color: white;
    font-weight: 900;
}

.roadmap-control img {
    /* width: 100%; */
    color: black;
}

.roadmap-control video {
    border: solid black 10px;
    border-radius: 20px;
    width: 100%;
    color: black;
}

.roadimage {
    height: 220px;
    width: 450px;
}

@media (min-width: 767px) {
    .roadimage {
        height: 300px;
        width: 800px;
    }

}