.teammember-control {
    width: 100%;
    text-align: center;
    justify-content: center;
    font-family: newThing;
}

.teammember-control img {
    width: 100%;
    text-align: center;
    border: solid black 2px;
    border-radius: 0px;
}

.teammember-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    text-align: center;
}

.teammember-name {
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    /* font-style: italic; */
    color: white;
    font-weight: bold;
    text-align: center;
    /* padding-left: 30px 20px; */
}

.teammember-occupation {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    color: #8a8a8a;
    padding: 5px 50px;
    /* border-radius: 0px; */
    text-align: center;
}

@font-face {
    font-family: newThing;
    src: url(/src/components/header/ArchivoNarrow-VariableFont_wght.ttf);
  }