.leet-control {
    padding-top: 0px;
    padding-bottom: 60px;
    background-image: url("../../assets/brickwall.png");
    border: solid black 10px;
    margin-bottom: 45px;
    
}

.leet-control header {
    font-size: 50px;
    line-height: 50px;
    color: rgb(0, 0, 0);
    font-style: italic;
    font-weight: 900;
    margin-bottom: 20px;
}

.leet-control header span {
    color: white;
    font-family: newerThing;
}

@font-face {
    font-family: newerThing;
    src: url(../../assets/fonts/Poppins-ExtraLight.ttf);
  }

@font-face {
    font-family: newThing;
    src: url(/src/components/header/Poppins-ExtraLight.ttf);
  }

.remaining {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 50px;
    color: white;
    font-style: italic;
    font-weight: 900;
}

.remaining span {
    color: #c8ff00;
}

.slide-arrows {
    margin-top: 30px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: black;
}

.caroImg {
    border: solid black 2px;
    border-radius: 0px;
}

.slide-arrows a{
    cursor: pointer;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: black;
    border: black;
}

.cards_layout2{
    justify-content: stretch;
    display: flex;
    flex-direction: row!important;
}

.utility_right_bar{
    display: flex;
    align-items: center;
    justify-content: center;
}