.mint-image img {
    width: 100%;
    border: solid black 2px;
    border-radius: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.video {
    width: 80%;
    border: solid black 2px;
    border-radius: 0px;
}

.mint-control {
    /* background-color: #1b1b68;
     */
    background-image: url(../../assets/10DickBackground.png);
    /* padding-top: 60px; */
    /* padding-bottom: 30px; */
    /* border-bottom: 2px solid black;
    border-top: 2px solid black; */
}

@font-face {
    font-family: newerThing;
    src: url(../../assets/fonts/Poppins-ExtraLight.ttf);
  }

@font-face {
    font-family: newThing;
    src: url(/src/components/header/Poppins-ExtraLight.ttf);
  }


.mint-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mint-description header {
    font-size: 50px;
    padding-bottom: 20px;
    color: white;
    font-weight: 900;
    font-style: italic;
    line-height: 50px;
    text-align: center;
    font-family: newThing;
}

.mint-description header span {
    color: white;
    font-family: newThing;
}

.mint-description p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 900;
    font-size: 20px;
    font-family: newThing;
}

.mint-description .number-control {
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.mint-description .number-control span{
    font-size: 30px;
    color: rgb(82, 25, 25);
    font-weight: 900;
}

@font-face {
    font-family: newThing;
    src: url(/src/components/header/Poppins-ExtraLight.ttf);
  }

.ybutton {
    background-color: #7eff06;
    color: black;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    min-width: 80px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
    font-weight: 700;
    border: solid 5px black;
    border-radius: 20px;
    font-family: newerThing;
}

.ybutton:hover{
    transform: scale(1.1);
}

.flex-column{
    display: flex;
    flex-direction: column;
}

.emailBox {
    padding-top: 20px;
}

.outerBox {
    margin-bottom: 80px;
    border: solid rgb(255, 255, 255) 3px;
    box-shadow: 0px 0px 25px red;
    /* border-radius: 20px; */
    background-image: url(../../assets/Capture.PNG);
    color: white;
    /* background-position: 20%; */
    padding-bottom: 20px;
}

.outerBox text {
    font-size: 40px;
}

.outerBox texter {
    font-size: 20px;
}

@media (min-width: 767px) {
    .emailBox {
        padding-top: 20px;
    }
    
    .outerBox {
        margin-bottom: 80px;
        border: solid rgb(255, 255, 255) 3px;
        box-shadow: 0px 0px 25px red;
        /* border-radius: 20px; */
        background-image: url(../../assets/Capture.PNG);
        color: white;
        /* background-position: 20%; */
        padding-bottom: 20px;
    }
    
    .outerBox text {
        font-size: 40px;
    }
    
    .outerBox texter {
        font-size: 20px;
    }
}