.about-control {
    background-image: url(../../assets/brickwall.png);
    background-image: url(../../assets/Chimpster.png);
    background-color: rgb(223, 107, 107);
    color: black;
    /* padding-top: 60px; */
    /* padding-bottom: 60px; */
    padding-left: 50px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    
}

@font-face {
    font-family: newThing;
    src: url(/src/components/header/Poppins-Regular.ttf);
  }

.image-control img {
    width: 100%;
    /* display: none; */
}

.about-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-description header {
    /* padding-top: 60px; */
    /* font-size: 50px; */
    line-height: 50px;
    color: black;
    font-style: italic;
    font-weight: bolder;
}

.about-description p {
    color: rgb(0, 0, 0);
    font-weight: 900;
    font-size: 18px;
    font-family: newThing;
}
.about-description .about-title{
    color: white;
    font-style: normal;
    /* font-size: 80px; */
    font-weight: 700;
    font-family: "PoppinsExtraLight";
    text-align: center;
    
}
.about-description .about-text{
    font-family: "PoppinsExtraLight";
    color: #7E7E7F;
}
.about-description .about-paragraph{
    color: white;
    font-style: normal;
    font-size: 27px;
    font-weight: 400;
    font-family: "PoppinsExtraLight";
    text-align: center;
}
.about-description p span {
    color: #000000;
}

.about-description header span {
    color: #000000;
}


.hidden {
    display: none;
}

.bidden {
    font-size: 80px;
    padding-top: 0px;
    margin-top: 0px;
}

.bog {
    display: none;
}

.sickButton {
    transition-duration: .2s;

    font-family: newerthing;
    border: solid white 3px;
    padding: 5px;
    font-size: 20px;
    border-radius: 10px;
    background-color: rgb(255, 0, 0);
    box-shadow: 0px 0px 25px red;
    padding-left: 10px;
    padding-right: 10px;
}

.sickButton:hover {
    transition-duration: .2s;
    font-family: newerthing;
    border: solid rgb(255, 255, 255) 3px;
    padding: 5px;
    font-size: 20px;
    border-radius: 10px;
    background-color: rgb(0, 255, 17);
    box-shadow: 0px 0px 25px rgb(0, 255, 51);
    padding-left: 10px;
    padding-right: 10px;
}

@media only screen and (max-width: 1000px) {

    .bog {
        display:contents;
    }
    .about-control {
        background-image: url(../../assets/brickwall.png);
        background-image: url(../../assets/Chimpster.png);
        padding: 0px;
        /* background-image: url(../../assets/eeeff.png); */
    }
    img {
        /* display:none; */
        display: flex;
    }
    .hidden {
        display:block;
    }
    .bidden {
        font-size: 50px;
    }
}